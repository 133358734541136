import Layout from 'common/components/Layout'
import Seo from 'components/seo'
import MessageVideo from 'containers/MessageVideo'
import WebinarHero from 'containers/WebinarHero'
import Results from 'containers/Results'
import TestimonialsMixed from 'containers/TestimonialsMixed'
import * as React from 'react'
import { ConstructionOutlined } from '@mui/icons-material'

const RestaurantLandingPage = ({ location }) => {
  const contactUsFooterOverRide = `Everything you need to know about the ERC`
  const title = 'Register for this free webinar'
  function scrollToAnchorId(id) {
    const yOffset = -80;
    const anchor = document.getElementById(id);
    const y = anchor.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, bahavior: "smooth"});
    console.log(anchor);
  }
  return (
    <Layout
      location={location.pathname}
      contactUsFooterOverride={contactUsFooterOverRide}
      titleOverride={title}
      ctaOverride={{
        buttonText: "Register Today",
        target: "#register",
        customHandler: scrollToAnchorId,
        anchorId: "registrationForm"
      }}
    >
      <Seo title={title} />
      <WebinarHero />
      <TestimonialsMixed />
      <MessageVideo />
      <Results />
    </Layout>
  )
}

export default RestaurantLandingPage
