import Heading from 'common/components/Heading'
import SectionHeader from 'common/components/SectionHeading'
import Text from 'common/components/Text'
import Container from 'common/components/UI/Container'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import SectionWrapper, {
  Testimonial,
  TestimonialsWrapper,
} from './testimonials.style'
import { Avatar, AvatarGroup } from '@mui/material';

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        TESTIMONIALS_MIXED {
          slogan
          title
          items {
            id
            title
            credit
            ownerName
            ownerPhoto {
              publicURL
            }
            occupation
            shortTestimonial
            image {
              publicURL
            }
            detailedTestimonial
            detailedTestimonial2
            summaryText
          }
        }
      }
    }
  `)
  const { slogan, title, items } = data.ertCreditJson.TESTIMONIALS_MIXED;

  return (
    <SectionWrapper>
      <Container width="1270px">
        <SectionHeader title={title} subtitle={slogan} color="#598A97" />
        <TestimonialsWrapper>
          {items.map((item) => {
            return (
              <Testimonial key={item.id}>
                <Heading as="h3" content={item.title} className="title" />
                <Heading as="h4" content={item.credit} className="credit" />
                <div className="owner-container">
                  <AvatarGroup>
                    {item.ownerPhoto.map((photo, index) => {
                      return (
                        /** <div
                          className={`image-content image-${index}`}
                          key={index}
                        >
                          <img src={photo.publicURL} alt="owner" />
                        </div> */
                        <Avatar alt={item.ownerName} src={photo.publicURL} />
                      ) 
                    })}
                  </AvatarGroup>
                  <div>
                    <Text content={item.ownerName} className="owner-name" />
                    <Text content={item.occupation} className="occupation" />
                  </div>
                </div>
                <Text
                  content={item.shortTestimonial}
                  className="shortTestimonial"
                />
                <Text
                  content={item.detailedTestimonial}
                  className="detailedTestimonial"
                />
                <div className="image-container">
                  <img src={item.image.publicURL} alt="store" />
                </div>
                {item.detailedTestimonial2 !== '' && (
                  <Text
                    content={item.detailedTestimonial2}
                    className="detailedTestimonial2"
                  />
                )}
                <Text content={item.summaryText} className="summaryText" />
              </Testimonial>
            )
          })}
        </TestimonialsWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default Testimonials
